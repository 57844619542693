import { onMounted, ref } from 'vue'

export const MOBILE_BREAKPOINT = 640
export const TABLET_BREAKPOINT = 1024

const isMobile = ref(false)
const isTablet = ref(false)
const isDesktop = ref(true)

export function useScreenSize() {
  onMounted(() => {
    window.addEventListener('resize', update)
    update()
  })

  return {
    isMobile,
    isTablet,
    isDesktop
  }
}

function update() {
  if (window.innerWidth < MOBILE_BREAKPOINT) {
    isMobile.value = true
    isTablet.value = false
    isDesktop.value = false
    return
  }

  if (window.innerWidth < TABLET_BREAKPOINT) {
    isMobile.value = false
    isTablet.value = true
    isDesktop.value = false
    return
  }

  isMobile.value = false
  isTablet.value = false
  isDesktop.value = true
}
